import {
  ConfigType,
} from './ConfigType';

export const ATSYS_HSG_CONFIG: ConfigType = {
  htmlFileOverride: 'src/userWeb/index.atsys_hsg.ejs',
  hlsServers: [
    'https://server1.hsg.ssnetworks.kr:4431',
    'https://server2.hsg.ssnetworks.kr:4432',
    'https://server3.hsg.ssnetworks.kr:4433',
  ],
};

export default function getATSYS_HSGConfig() {
  return ATSYS_HSG_CONFIG;
}
