import { ATSYS_GASAN_CONFIG } from './atsys_gasan';
import { ATSYS_HSG_CONFIG } from './atsys_hsg';
import {
  ConfigType,
} from './ConfigType';
import { HSG_CONFIG } from './hsg';

const defaultConfig: ConfigType = {
  hlsServers: [
    'https://cowrs.saturn.ssnetworks.kr:4433',

    'https://beta.ssnetworks.kr',
    'https://beta2.ssnetworks.kr',
    'https://ssnw003.gasan.ssnetworks.kr:4432',
    'https://ssnw004.gasan.ssnetworks.kr:4433',
    'https://venus.ssnetworks.kr',
    'https://venus2.ssnetworks.kr',
    'https://saturn.ssnetworks.kr', // <-- NOT jupiter, but test-server 1
    // 'https://jupiter.ssnetworks.kr',
    // move to new office: temp workaround for dns update
    'https://ssnw001.ssnetworks.kr:5531', //
    'https://ssnw001.ssnetworks.kr:5532', //

    'https://ssnw002.ssnetworks.kr:5532', //
    'https://ssnw002.ssnetworks.kr:5533', //
    'https://ssnw005.ssnetworks.kr:5535', //
    'https://real.saturn.ssnetworks.kr',

  ],
};

export default function getConfig(configFileName?: string): ConfigType {
  if (configFileName === 'hsg') {
    return HSG_CONFIG;
  }
  if (configFileName === 'atsys_gasan') {
    return ATSYS_GASAN_CONFIG;
  }
  if (configFileName === 'atsys_hsg') {
    return ATSYS_HSG_CONFIG;
  }
  return defaultConfig;
}
