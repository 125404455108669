import {
  ConfigType,
} from './ConfigType';

export const ATSYS_GASAN_CONFIG: ConfigType = {
  htmlFileOverride: 'src/userWeb/index.atsys_gasan.ejs',
  hlsServers: [
    'https://beta.ssnetworks.kr',
    'https://beta2.ssnetworks.kr',
    'https://ssnw003.gasan.ssnetworks.kr:4432',
    'https://ssnw004.gasan.ssnetworks.kr:4433',
    'https://venus.ssnetworks.kr',
    'https://venus2.ssnetworks.kr',
    'https://saturn.ssnetworks.kr', // <-- NOT jupiter, but test-server 1
    // 'https://jupiter.ssnetworks.kr',
    // move to new office: temp workaround for dns update
    'https://ssnw001.ssnetworks.kr:5531', //
    'https://ssnw001.ssnetworks.kr:5532', //

    'https://ssnw002.ssnetworks.kr:5532', //
    'https://ssnw002.ssnetworks.kr:5533', //
    'https://ssnw005.ssnetworks.kr:5535', //
    'https://real.saturn.ssnetworks.kr',
  ],
};

export default function getATSYS_GASANConfig() {
  return ATSYS_GASAN_CONFIG;
}
